import { AppClient, BioLibSingleton, ISlimApp, Paginated } from '@biolibtech/biolib-js';
import { channel } from 'redux-saga';
import { call, fork, put, take } from 'redux-saga/effects';
import config from '../config';
import { signInWithRefreshToken } from './userSaga';
import userFlowSlice from '../features/userFlow/userFlowSlice';
import AuthCookie from '../features/userFlow/utils/AuthCookie';
import LocalStorageManager from '../features/userFlow/utils/LocalStorageManager';
import { appsActions } from '../state/apps/appsActions';

export const eventChannel = channel();

function* watchEventChannel() {
    while (true) {
        const action = yield take(eventChannel);
        yield put(action);
    }
}

function* initialize() {
    try {
        BioLibSingleton.setConfig({ baseUrl: `${config.baseURL}`, refreshToken: undefined });
        AppClient.setApiClient(BioLibSingleton.get());
    } catch (error) {
        console.error(error);
    }
}

export default function* initializationSaga() {
    if (typeof window !== 'undefined') {
        yield call(initialize);
        yield fork(watchEventChannel);
    }
}
