import * as React from 'react';
import config from '../config';

const contentLinks: { text: string; path: string; }[] = []

export default class FooterTeamSubdomain extends React.Component {
    public render() {
        return (
            <footer className='mt-20 font-sans text-base pb-6'>
                <div className='container'>
                    <hr className='mt-0 mb-4'/>
                    <div className='flex flex-col space-y-6 sm:space-y-0 sm:flex-row items-center justify-between'>

                        <p>
                            <span title={config.buildInfo}>
                                © BioLib 2025
                            </span>
                            <span className='mx-3'>·</span>
                            <a href='https://biolib.com/legal/terms/' className='text-current'>Terms</a>
                            <span className='mx-3'>·</span>
                            <a href='https://biolib.com/legal/privacy-policy/' className='text-current'>Privacy</a>
                        </p>

                        <div className='flex flex-row items-center space-x-6'>
                            {contentLinks.map(({ text, path }, index) => (
                                <a
                                    key={index}
                                    href={`https://biolib.com${path}`}
                                    className='text-gray-600 hover:text-gray-200'
                                >
                                    {text}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
